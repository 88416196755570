import { defineStore } from 'pinia';
import { reactive, watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGeoIPStore } from './geoip';
import { checkPlan, verifyTokenUser, checkPaid } from '@/services/backend';
import { verifyToken } from '@/services/jose';
import { updateUrlQuery } from '@/services/windowAPI'

const quantityLimit = import.meta.env.VITE_QUANTITY_LIMIT;
const tokenName = import.meta.env.VITE_SESSION_TOKEN_NAME;

export const useCheckoutStore = defineStore('checkout', () => {
  const geoIPStore = useGeoIPStore();
  const route = useRoute();
  const router = useRouter();
  const planDescription = ref({});

  const state = reactive({
    firstName: '',
    lastName: '',
    email: '',
    emailRepeat: '',
    plan: route.query.plan || 'speedify-unlimited-monthly',
    country: geoIPStore.details.country || 'US',
    coupon: route.query.coupon || '',
    postalCode: '',
    currency: geoIPStore.details.currency || 'USD',
    quantity: route.query.quantity || 1,
    serverCode: '',
    serverName: '',
    servers: route.query.servers || 0,
    price: null,
    upsold: false,
    vatNumber: '',
    loggedIn: null,
    isPaid: null,
  });

  const getPlanDescription = async () => {
    const response = await checkPlan(state.plan);
    if (response.status === 404) {
      router.push({ query: { plan: 'speedify-unlimited-monthly' }});
    }
    if (!response.err) {
      planDescription.value = await response.json();
    }
  };

  // anti-pattern on a vue SPA, but recurlyJS doesn't support detaching subscriptions from its checkoutPricing instance.
  // so we opt to remove servers for example by cutting the 'servers' query from the url and reload.
  // hiding the relevant data-recurly input from the DOM should be sufficient,
  // but introduces significant performance issues, like crashing the tab if you play with the tab's history.
  const reloadCartWithoutServers = () => {
    router.push({ name: 'CheckoutCart' } + window.location.search)
      .then(() => {
        const newQueryString = Object.entries(route.query)
          .filter(q => !q[0].match(/servers/i))
          .map(arr => arr.join('='))
          .join('&');
        window.location.search = newQueryString;
      });
  };

  // We set up watchers here since html min/max attributes are not capable of handling
  // manual user changes to the input field. If a user manually changes it to a 0
  // or a negative number, set it back to 1
  // or squash floats down to nearest integer (3.5 -> 3)
  watch(
    () => state.quantity,
    newSubscriptionQuantity => {
      if (newSubscriptionQuantity <= 0) {
        state.quantity = 1;
      } else if (newSubscriptionQuantity > quantityLimit) {
        state.quantity = quantityLimit;
      } else if (!Number.isInteger(newSubscriptionQuantity)) {
        state.quantity = Math.floor(newSubscriptionQuantity);
      }
      // workaround: https://github.com/Connectify/speedify-cart/pull/340#discussion_r1525411310
      updateUrlQuery('quantity', state.quantity);
    },
  );

  watch(
    () => state.servers,
    newServerQuantity => {
      if (newServerQuantity <= 0) {
        state.servers = 1;
      } else if (newServerQuantity > quantityLimit) {
        state.servers = quantityLimit;
      } else if (!Number.isInteger(newServerQuantity)) {
        state.servers = Math.floor(newServerQuantity);
      }
      // workaround: https://github.com/Connectify/speedify-cart/pull/340#discussion_r1525411310
      updateUrlQuery('servers', state.servers);
    },
  );

  watch(() => geoIPStore.details.currency, () =>  {
    state.currency = geoIPStore.details.currency;
  });

  watch(() => geoIPStore.details.zipCode, () => {
    // for now, will only run when geoIPStore.refresh is invoked
    state.postalCode = geoIPStore.details.zipCode;
  });

  const update = (obj) => {
    for (const key in obj) {
      state[key] = obj[key];
    }
  };

  const productIsServer = state.plan.includes('server');

  // grab info from cookie about the user. move this to a new store when we're interested in more than just email
  (async () => {
    if (await verifyTokenUser()) {
      const tokenPayload = await verifyToken();
      if (tokenPayload?.userid){
        state.loggedIn = true;
        state.email = tokenPayload.email;
        state.emailRepeat = tokenPayload.email;
        state.isPaid = await checkPaid();
        if (state.isPaid === false && productIsServer) {
          const term = state.plan.includes('monthly') ? 'monthly' : 'yearly';
          router.push({ query: { plan: `speedify-teams-unlimited-${term}`, servers: 1, coupon: state.coupon, addedTeam: 1 }});
        }
      }
    }
  })();

  return { checkoutData: state, getPlanDescription, planDescription, update, reloadCartWithoutServers, productIsServer };
});
